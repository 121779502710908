@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
	:root {
		--lp-header-height: 80px;
		--lp-welcome-height: calc(100svh - var(--lp-header-height));
	}
}

@layer utilities {
	@variants responsive {
		/* Hide scrollbar for Chrome, Safari and Opera */
		.no-scrollbar::-webkit-scrollbar {
			display: none;
		}

		/* Hide scrollbar for IE, Edge and Firefox */
		.no-scrollbar {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
}

@layer base {
	blockquote,
	dl,
	dd,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	hr,
	figure,
	p,
	pre {
		margin: 0;
	}

	@font-face {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		src: url('../assets/fonts/Manrope-Regular.woff2') format('woff2');
	}

	@font-face {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 500;
		src: url('../assets/fonts/Manrope-Medium.woff2') format('woff2');
	}

	@font-face {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 700;
		src: url('../assets/fonts/Manrope-Bold.woff2') format('woff2');
	}
}

html,
body {
	padding: 0;
	margin: 0;
}

/*html, body {*/
/*	-webkit-overflow-scrolling: auto;*/
/*	overscroll-behavior: none;*/
/*	!*overflow: hidden;*!*/
/*}*/

a {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: inherit;
}

* {
	box-sizing: border-box;
	font-family: 'Manrope';
}

/*added to avoid auto zoom on ios*/
input, textarea {
	font-size: 16px !important;
}

/* Disables the scroll bar display */
*::-webkit-scrollbar {
	display: none;
}

* {
	scrollbar-width: none;
}

body {
	-ms-overflow-style: none;
	overflow: scroll;
}
/* Disables the scroll bar display */
